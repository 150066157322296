@use "/src/assets/variables.scss" as *;

.line-schedule-form {
    display: flex;
    justify-content: flex-start;

    .input-wrapper {
        margin: 0 0 0 15px;

        @media (max-width: 768px) {
            margin: 0;
        }

        .error-msg {
            position: absolute;
            padding: 0 5px;
            font-size: 12px;
            color: $color-alert;
            line-height: 1.2;
        }

        .rbt-input-main {
            border: 0;
        }
    }

    .btn {
        margin: 0 0 0 25px;

        @media (max-width: 768px) {
            margin: 0;
        }
    }
}

.line-search{
 & .toggle-button {
    background: transparent;
    border: 0;
    bottom: 0;
    padding: 0 12px;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
  }

}

