$color-primary: #212b60;
$color-primary-light: #5d87ff;
$color-alert: #b10069;
$color-white: #ffffff;
$color-periwinkle-gray: #cbd0ee;
$color-body: #4f5266;
$color-active: #6f9dff;
$color-a-menu-contact: #2c3c73;
$color-mobile-light: #37489f;
$color-mischka: #dadce6;
$color-very-light-blue: #e9f6fc;
$color-gray-text: #868686;
$color-san-marino: #475eaf;
$color-top-bottom-border: #979797;
$color-border-top: #f3f3f3;
$color-light-green: #7ac143;
$color-gray-popover: #d9d9d9;
$color-gallery: #eeeeee;
$color-athens-gray: #f0f1f6;
$color-alabaster: #fcfcfc;
$color-gray: #858585;
$color-astronaut: #2c3c73;
$color-mine-shaft: #333333;
$color-st-tropaz: #286090;
$color-periwinkle-gray: #c0c4ea;
$color-black: #000000;
$color-shadow: #00000026;
$color-cornflower-blue: #66afe9;
$color-gray-medium: #707070;
$color-silver-chalice: #b2b2b2;
$color-silver: #cccccc;
$color-alto: #dcdcdc;
$color-border-gray: #e5e5e5;
$color-violet-light: #23ace5;
$color-cherub: #f8d7da;
$color-light-violet-blue: #cad0ed;
$color-white-light-background: #edebec;
$color-gray-border: #dbdad9;
$color-light-blue-background: #e8f7fc;
$color-gray-dropdown: #bcbcbc;
$color-mischka2: #d2d4e0;
$color-blue-button: #002d72;
$color-casper-border: #D8E3F2;
$font-primary: 'Magistral', sans-serif;
$font-secondry: 'Assistant', sans-serif;
$font-secondry2: 'Assistant';
$font-secondary3: 'Assistant', Helvetica, sans-serif;
$font-secondary4: 'Magistral';
$font-secondary5: 'AdobeBlank';
$font-print: 'Times New Roman';
$font-primary-rtl: 'Heebo', sans-serif;
$font-secondry-rtl: 'Heebo';
