@use '/src/assets/new_variables.scss' as *;
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/mixins/breakpoints';

.gnp-container {
  position: relative;
  min-height: 600px;
  border-radius: 10px;
  margin: 190px 60px 0;
  background: 100% 100%;
  background-size: cover;

  & .gnp-header {
    position: absolute;
    left: 86px;
    height: 26px;
    top: 48px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 600;

    & a {
      color: $color-primary;
    }

    & .go-back-link {
      font-family: $font-secondry;
      text-decoration: none;
    }
  }

  & .gnp-controls {
    padding: 140px 86px 115px;

    & .main-row {
      height: 100%;

      & .text {
        & h3 {
          font-family: $font-primary;
          font-size: 100px;
          line-height: 115px;
          font-weight: 400;
          color: $color-primary;
        }
      }
      & .btn-row {
        & .required {
          display: none;
        }
        & .label,
        & .form-control,
        & .dropdown-menu .dropdown-item {
          font-family: $font-secondry;
          color: $color-primary;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
        }
        & .form-control {
          height: 60px;
          border-radius: 10px;
        }

        & .item-selected {
          & .form-control {
            font-weight: 600;
          }
        }

        & .error-msg {
          font-family: $font-secondry;
          color: $color-alert;
        }

        & .line-search-form {
          margin-top: 0;

          & .input-wrapper {
            margin-top: 0;
            
             &:not(:last-child) {
              padding-inline-end: 38px;
             }
          }
            
          & .btn.btn-primary {
            height: 60px;
            border-radius: 10px;
            font-family: $font-secondry;
          }
        }
      }
    }

    &.regular {
      & .main-row {
        & .btn-row {
          & .btn.btn-primary {
            border-radius: 10px;
            font-family: $font-secondry;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .gnp-container {
    margin: 50px 20px 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    & .gnp-header {
      font-size: 16px;
      line-height: 18px;
      top: 30px;
    }

    & .gnp-controls {
      padding: 75px 86px 60px;
      
      &.extra {
        padding-top: 67px;
      }

      & .main-row {
        height: 100%;

        & .text {
          & h3 {
            font-family: $font-primary;
            font-size: 38px;
            line-height: 50px;
            color: $color-primary;
          }
        }
        & .btn-row {
          & .required {
            display: none;
          }

          & .line-search-form {
            & .input-wrapper {
              &:not(:last-child) {
                padding-inline-end: 0;
              }
            }

            & .btn.btn-primary {
              margin-top: 25px;
            }
          }
        }
      }

      &.regular {
        & .main-row {
          & .btn-row {
            & .btn.btn-primary {
              width: 100%;
              height: 50px;
            }
          }
        }
      }
    }

    & .ms-20 {
      margin-top: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .gnp-container {
    & .gnp-header {
      left: 25px;
    }

    & .gnp-controls {
      padding-top: 28px;
      padding-inline-start: 25px;
      padding-inline-end: 9px;
      padding-bottom: 38px;

      .line-search-form {
        & .input-wrapper {
          margin-bottom: 20px;
        }
      }
    } 
  }
}
