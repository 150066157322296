$color-primary: #002d72;
$color-primary-light: #5d87ff;
$color-primary-dark: #18191b;
$color-alert: #b10069;
$color-white: #ffffff;
$color-periwinkle-gray: #cbd0ee;
$color-body: #4f5266;
$color-active: #6f9dff;
$color-a-menu-contact: #2c3c73;
$color-mobile-light: #37489f;
$color-mischka: #dadce6;
$color-very-light-blue: #e9f6fc;
$color-gray-text: #868686;
$color-san-marino: #475eaf;
$color-top-bottom-border: #979797;
$color-border-top: #f3f3f3;
$color-light-green: #7ac143;
$color-gray-popover: #d9d9d9;
$color-gallery: #eeeeee;
$color-athens-gray: #f0f1f6;
$color-alabaster: #fcfcfc;
$color-gray: #858585;
$color-astronaut: #2c3c73;
$color-mine-shaft: #333333;
$color-st-tropaz: #286090;
$color-periwinkle-gray: #c0c4ea;
$color-black: #000000;
$color-shadow: #00000026;
$color-cornflower-blue: #66afe9;
$color-gray-medium: #707070;
$color-silver-chalice: #b2b2b2;
$color-silver: #cccccc;
$color-alto: #dcdcdc;
$color-border-gray: #e5e5e5;
$color-violet-light: #23ace5;
$color-cherub: #f8d7da;
$color-light-violet-blue: #cad0ed;
$color-white-light-background: #edebec;
$color-gray-border: #dbdad9;
$color-casper-border:#D8E3F2;
$color-light-blue-background: #e8f7fc;
$color-gray-dropdown: #bcbcbc;
$color-mischka2: #d2d4e0;
$color-green-vogue: #032b5c;
$color-golden-bell: #c87300;
$color-hoki: #6681aa;
$color-link-water: #d8e3f2;
$color-chambray: #33548e;
$color-deep-graphite: #18191b;
$color-cool-gray: #868787;
$color-light-mist: #e7e7e7;
$color-skyline-blue: #99abc7;
$color-cloudy-day: #adbcd2;
$color-bright-ocean: #009cde;
$color-baby-blue-wash: #addef5;
$color-mobile-link: #ff9e1b;
$color-error-input: #fd1818;
$color-woodsmoke: #18191b;
$color-casper: #adbcd2;
$color-rock-blue: #99abc7;
$color-blue-tools-forms: #ebf1f8;
$color-facebook: #3b5998;
$color-linkedin: #0077b5;
$color-twitter-x: #14171a;
$color-whatsapp: #25d366;
$color-tag-gray: #d8e3f2;
$color-blue-button: #002d72;
$color-light-blue-gray: #adbcd2;
$color-blue-gray: #99abc7;
$color-dark-blue-gray: #6681aa;
$color-iron: #d9dada;
$color-transparent: transparent;
$color-chrome-white: #e4f2d3;
$color-lima: #78be21;
$color-gray-border-focus: #00000014;
$color-form-text-green: #558b11;
$color-form-indicator-green: #78be21;
$color-red-background: #93272c;
$color-green-background: #67a41b;
$color-blue-info: #33548E;

$font-primary: 'Magistral', sans-serif;
$font-secondry: 'Assistant', sans-serif;
$font-secondry2: 'Assistant';
$font-secondary3: 'Assistant', Helvetica, sans-serif;
$font-secondary4: 'Magistral';
$font-secondary5: 'AdobeBlank';
$font-print: 'Times New Roman';
$font-primary-rtl: 'Heebo', sans-serif;
$font-secondry-rtl: 'Heebo';

$menu-animation-duration: 300ms;
